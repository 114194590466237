import { Organization, User } from "~/src/api";

export async function getUserFollowStats(userId?: string) {
  if (!userId)
    return {
      following: 0,
      followers: 0,
    };

  const [userFollows, orgFollows, userFollowers] = await Promise.all([
    User.getFollows({ originId: userId, limit: 0 }),
    Organization.getFollows({ userId: userId, limit: 0 }),
    User.getFollows({ targetId: userId, limit: 0 }),
  ]);

  return {
    following: userFollows.meta.total + orgFollows.meta.total,
    followers: userFollowers.meta.total,
  };
}
